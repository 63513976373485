import CountUp from 'react-countup';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';

import { TextToolTip } from '@/components/shared/TextToolTip';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { PATHS } from '@/constants/page-paths';
import { useAuth } from '@/lib/hooks/useAuth';
import useTwinVault from '@/lib/hooks/useTwinVault';
import TwinCoin from '@/pages/user/profile/components/TwinCoin';
import { ProfileDropdown } from '../../ProfileDropdown';

export const RightNav = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { vaultData, isLoading } = useTwinVault();

  return (
    <div className="flex items-center justify-between gap-2 md:gap-6 h-full">
      {isAuthenticated ? (
        <>
          {isLoading ? (
            <Skeleton className="w-20 h-6" />
          ) : (
            <div className="flex justify-center items-center px-2 py-1 gap-1 sm:gap-2 rounded-full bg-secondary text-secondary-foreground">
              <div className="flex gap-1 items-center sm:gap-2">
                <TwinCoin />
                <div className="font-semibold text-sm">
                  <CountUp
                    end={parseFloat(vaultData?.twinPoint) as unknown as number}
                    decimals={0}
                    duration={0.5}
                  />
                </div>
              </div>
              <span>
                <TextToolTip label="Buy Points" side="bottom">
                  <Button
                    onClick={() => navigate(PATHS.BUY_POINTS)}
                    className="h-6 w-6 px-1 ml-1 rounded-full"
                  >
                    <AiOutlinePlus />
                  </Button>
                </TextToolTip>
              </span>
            </div>
          )}
          <ProfileDropdown />
        </>
      ) : (
        <div className="flex items-center gap-2 md:gap-4">
          <Link to={PATHS.SIGN_IN}>Sign-in</Link>
          <Link to={PATHS.REGISTER}>Register</Link>
        </div>
      )}
    </div>
  );
};
