import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { wordLimitSlice, WordLimitSlice } from './slices/chat.slice';
import { appSettingsSlice, SettingsSlice } from './slices/settings.slice';
import { themeSlice, ThemeSlice } from './slices/theme.slice';
import {
  profileSlice,
  ProfileSlice,
  TokenSlice,
  tokenSlice,
  userSlice,
  UserSlice,
} from './slices/user.slice';

export const useSettingsStore = create<ThemeSlice & SettingsSlice>()(
  persist(
    (...a) => ({
      ...themeSlice(...a),
      ...appSettingsSlice(...a),
    }),
    { name: 'app-store' }
  )
);

export const useUserStore = create<UserSlice>()(
  persist(
    (...a) => ({
      ...userSlice(...a),
    }),
    { name: 'user-store' }
  )
);

export const useProfileStore = create<ProfileSlice>()((...a) => ({
  ...profileSlice(...a),
}));

export const useTokenStore = create<TokenSlice>()((...a) => ({
  ...tokenSlice(...a),
}));

export const useChatStore = create<WordLimitSlice>()(
  persist(
    (...a) => ({
      ...wordLimitSlice(...a),
    }),
    { name: 'chat-word-limit' }
  )
);
