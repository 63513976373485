// import darkIcon from '@/assets/icons/twin-coin.svg';
import twincoin from '@/assets/icons/twin-coin-blue.svg';

// import { cn } from '@/lib/utils/cn';
// import { useSettingsStore } from '@/store';

const TwinCoin = ({
  // className,
  size = 6,
}: {
  className?: string;
  size?: number;
}) => {
  // const { themeMode } = useSettingsStore();

  // const twinSmallLogo = themeMode === 'dark' ? lightIcon : darkIcon;

  return (
    // <div
    //   className={cn(
    //     className,
    //     `flex items-center justify-center rounded-full bg-secondary border-[3px] border-primary`,
    //     `w-${size} h-${size}`
    //   )}
    // >
    //   <img src={twinSmallLogo} className={`w-${size / 2}`} />
    // </div>
    // <div className={cn(className, `flex items-center justify-center`)}>
    <img
      src={twincoin}
      className={`w-${size} h-${size} items-center justify-center flex`}
    />
    // </div>
  );
};

export default TwinCoin;
