import { useMemo, useState } from 'react';

import {
  BrowserProvider,
  FallbackProvider,
  JsonRpcProvider,
  JsonRpcSigner,
  parseEther,
} from 'ethers';
import { useQuery } from 'react-query';
import type { Account, Chain, Client, Transport } from 'viem';
import {
  useAccount,
  useBalance,
  useClient,
  useConnectorClient,
  useDisconnect,
  useEstimateGas,
  useGasPrice,
  type Config,
} from 'wagmi';

import { apiGetVault } from '@/services/user.api';

function useTwinVault() {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const TWIN_TOKEN_ADDRESS = import.meta.env.VITE_TWIN_TOKEN_ADDRESS;

  const [amount, setAmount] = useState<string | null>(null);

  // Helper function to convert a viem Wallet Client to an ethers.js Signer.
  const clientToSigner = (client: Client<Transport, Chain, Account>) => {
    const { account, chain, transport } = client as any;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    };
    const provider = new BrowserProvider(transport, network);
    return new JsonRpcSigner(provider, account.address);
  };

  // Hook to convert a viem Wallet Client to an ethers.js Signer.
  const useEthersSigner = ({ chainId }: { chainId?: number } = {}) => {
    const { data: client } = useConnectorClient<Config>({ chainId });
    return useMemo(
      () => (client ? clientToSigner(client) : undefined),
      [client]
    );
  };

  // Helper function to convert a viem Client to an ethers.js Provider.
  const clientToProvider = (client: Client<Transport, Chain>) => {
    const { chain, transport } = client as any;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    };

    if (transport.type === 'fallback') {
      const providers = (transport.transports as ReturnType<Transport>[]).map(
        ({ value }) => new JsonRpcProvider(value?.url, network)
      );
      return providers.length === 1
        ? providers[0]
        : new FallbackProvider(providers);
    }

    return new JsonRpcProvider(transport.url, network);
  };

  // Hook to convert a viem Client to an ethers.js Provider.
  const useEthersProvider = ({ chainId }: { chainId?: number } = {}) => {
    const client = useClient<Config>({ chainId });
    return useMemo(
      () => (client ? clientToProvider(client) : undefined),
      [client]
    );
  };

  const { data: vaultResponse, ...vaultQuery } = useQuery({
    queryKey: ['vault-details'],
    queryFn: apiGetVault,
  });

  const vaultData = vaultResponse?.data?.data ?? {};

  const { data: balanceData } = useBalance({ address });
  const userBalance = balanceData?.value;

  const { data: gasPrice } = useGasPrice();

  const { data: estimatedGasCost } = useEstimateGas({
    address: TWIN_TOKEN_ADDRESS,
    functionName: 'transfer',
    // args: [vaultData?.address, amount ? parseEther(amount) : 0],
    args:
      vaultData?.address && amount
        ? [vaultData.address, parseEther(amount)]
        : undefined, // Ensure amount is valid
    enabled: Boolean(vaultData?.address && amount), // Only run if both address and amount are available
  });

  return {
    isConnected,
    disconnect,
    vaultData,
    clientToSigner,
    useEthersSigner,
    clientToProvider,
    useEthersProvider,
    useAccount,
    userBalance,
    gasPrice,
    estimatedGasCost,
    setAmount,
    ...vaultQuery,
  };
}

export default useTwinVault;
